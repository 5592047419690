@use "@angular/material" as mat;
@include mat.core();
mat.$theme-ignore-duplication-warnings:true;
@import "variables";
@import "helpers/color";
@import "helpers/icon-size";

@import 'quill/dist/quill.snow.css';
////Theme colors
//
//@import "themecolors/default_theme";
//@include mat.all-component-themes($bluetheme);

//Theme colors

.bg-primary-100,.bg-primary-200{
  background-color: var(--mat-theme-primary-color) !important;
  filter: brightness(1.0) !important;
  opacity: 0.6 !important;
}

@import "themecolors/khdma_theme";
@include mat.all-component-themes($khdmatheme);

:fullscreen {
  @include mat.all-component-themes($khdmatheme);
  .mat-mdc-tab-link {
    background: white;
  }
}
.dark-theme {
  @include mat.all-component-colors($darkkhdmatheme);
}

// crystal_nexus_theme

//.crystal_nexus_theme {

  //--mat-theme-primary-color:  #87CEEB;
  //--mat-theme-accent-color: #FF9999;
  //@import "themecolors/khdma_theme";
  //@include mat.all-component-themes($khdmatheme);
  //@import "themecolors/crystal_nexus_theme";
  //@include mat.all-component-themes($crystalnexustheme);
  ////@include mat.all-component-themes($crystalnexustheme);
//}

 //.dark-theme .crystal_nexus_theme {
 //  @import "themecolors/crystal_nexus_theme";
 //  @include mat.all-component-colors($darkcrystalnexustheme);
 //}

// .cloud_scape_theme{
//   //@import "themecolors/cloud_scape_theme";
//   //@include mat.all-component-themes($cloudscapetheme);
// }
//
//.dark-theme .cloud_scape_theme {
//  @import "themecolors/cloud_scape_theme";
//  @include mat.all-component-colors($darkcloudscapetheme);
//}


//.tech_horizon_theme{
//  @import "themecolors/tech_horizon_theme";
//  @include mat.all-component-themes($techhorizontheme);
//}
//.dark-theme .tech_horizon_theme{
//  @import "themecolors/tech_horizon_theme";
//  @include mat.all-component-colors($darktechhorizontheme);
//}
//.data_stream_theme{
//  @import "themecolors/data_stream_theme";
//  @include mat.all-component-themes($datastreamtheme);
//}
//.dark-theme .data_stream_theme{
//  @import "themecolors/data_stream_theme";
//  @include mat.all-component-colors($darkdatastreamtheme);
//}
//.digital_dawn_theme{
//  @import "themecolors/digital_dawn_theme";
//  @include mat.all-component-themes($digitaldawntheme);
//}
//.dark-theme .digital_dawn_theme{
//  @import "themecolors/digital_dawn_theme";
//  @include mat.all-component-colors($darkdigitaldawntheme);
//}
//.innovation_hub_theme{
//  @import "themecolors/innovation_hub_theme";
//  @include mat.all-component-themes($innovationhubtheme);
//}
//.dark-theme .innovation_hub_theme{
//  @import "themecolors/innovation_hub_theme";
//  @include mat.all-component-colors($darkinnovationhubtheme);
//}
//.skyline_sync_theme{
//  @import "themecolors/skyline_sync_theme";
//  @include mat.all-component-themes($skylinesynctheme);
//}
//.dark-theme .skyline_sync_theme{
//  @import "themecolors/skyline_sync_theme";
//  @include mat.all-component-colors($darkskylinesynctheme);
//}
//.dark_cst_theme{
//  @import "themecolors/dark_theme";
//  @include mat.all-component-themes($darkcsttheme);
//}
//.dark-theme .dark_cst_theme{
//  @import "themecolors/dark_theme";
//  @include mat.all-component-colors($darkdarkcsttheme);
//}



//container layout
@import "overrides/materialoverrides";
@import "dark/dark";
@import "container";
@import "layouts/layouts";
@import "grid/grid";
@import "helpers/custom-flex";
@import "helpers/index";

@import "pages/auth";
@import "pages/dashboard";
@import "../../theme/responsive";

